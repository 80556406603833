<template>
  <v-layout row wrap max-width-1400 full-width ma-auto pb-5>
    <v-layout row wrap class="px-5" align-center justify-center>
      <v-img
        v-for="(image, index) in images"
        :key="index"
        :aspect-ratio="1"
        :class="{'mx-5': $vuetify.breakpoint.smAndUp, 'mx-2': $vuetify.breakpoint.xsOnly}"
        contain
        position="center center"
        :src="image"
        :lazy-src="image"
        max-width="200px"
        max-height="200px"
      ></v-img>
    </v-layout>
  </v-layout>
</template>

<script>
export default {
  name: 'ContentCompaniesStatic',
  props: {
    companies: Array
  },
  computed: {
    images () {
      let result = []
      this.companies.map(company => {
        result.push(require('@/assets/img/companies/' + company + '-full.png'))
      })
      return result
    }
  }
}
</script>
